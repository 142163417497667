
































































































































import incidenciaModule from "@/store/modules/incidencia-module";
import enumeracionModule from "@/store/modules/enumeracion-module";
import { DxScrollView } from "devextreme-vue/scroll-view";
import { DxSortable } from "devextreme-vue/sortable";
import { Component, PropSync, Vue } from "vue-property-decorator";
import { incidencia } from "@/shared/dtos/incidencias/incidencia";
import { incidenciaAllDto } from "@/shared/dtos/incidencias/incidenciaAllDto";
import { enumeracion } from "@/shared/dtos/enumeracion";
import { RouterNames } from "@/router/routernames";
@Component({
  components: {
    DxScrollView,
    DxSortable,
    KanbanList: () => import("@/components/TypeView/Kanban_List.vue"),
    IncidenciasFormulario: () =>
      import("@/views/proyectos/incidencias/incidenciasFormulario.vue"),
    XBtnActiveInActive: () =>
      import("@/components/TypeView/x-btnActiveInActive.vue"),
    Xbtn: () => import("@/components/buttons/x-btn.vue"),
  },
})
export default class IncidenciasKanban extends Vue {
  @PropSync("id_proyecto", { default: -1 }) id_proyectosync!: number;
  @PropSync("id_tarea", { default: -1 }) id_tareasync!: number;
  @PropSync("incidencias", { default: [] })
  incidencias_list!: incidenciaAllDto[];
  public lists: any[] = [];
  public search: string = "";

  public enumeracines_estados: enumeracion[] = [];

  public show_dialog_incident: boolean = false;
  public id_inicencia: number = -1;
  public shw_type: string = "active";

  created() {
    this.GetIncidencias();
    enumeracionModule.getenumeraciones_fromtipo(4).then((x: enumeracion[]) => {
      this.enumeracines_estados = x;
    });
  }

  public get incidencias() {
    this.lists = [];
    this.estados.forEach((status) => {
      if (this.search.trim() !== "") {
        this.lists.push({
          estado: status,
          tareas: incidenciaModule.incidencias_proyecto.filter((task) => {
            return (
              task.id_estado === status.id &&
              task.nombre.toLowerCase().includes(this.search.toLowerCase())
            );
          }),
        });
      } else {
        this.lists.push({
          estado: status,
          tareas: incidenciaModule.incidencias_proyecto.filter(
            (task) => task.id_estado === status.id
          ),
        });
      }
    });
    return "";
  }
  public get estados() {
    return this.enumeracines_estados;
  }

  get get_style_height() {
    let alto = this.$vuetify.breakpoint.height;
    return "height:" + (alto - 218) + "px";
  }

  public GetIncidencias() {
    return this.change_data_activos(this.shw_type);
  }

  public change_data_activos(activos: string) {
    this.shw_type = activos;
    //3 variantes
    // tarea -1 proyecto -1 (veo global)
    // tarea -1 veo proyecto
    // proyecto -1 veo tarea

    if (this.id_proyectosync === -1 && this.id_tareasync === -1) {
      if (activos === "active") {
        return incidenciaModule.getincidencias_proyectosActivas();
      } else {
        return incidenciaModule.getincidencias_proyectosInactivas();
      }
    } else if (this.id_tareasync === -1) {
      return incidenciaModule.getincidencias_proyecto_act_inact({
        id: this.id_proyectosync,
        act: activos === "active",
      });
    } else {
      return incidenciaModule.getincidencias_tarea_act_inact({
        id: this.id_tareasync,
        act: activos === "active",
      });
    }
  }

  public close_dialog() {
    this.show_dialog_incident = false;
    this.GetIncidencias().then((x: incidenciaAllDto[]) => {
      this.incidencias_list = x;
    });
    this.id_inicencia = -1;
  }

  onTaskDragStart(e: any) {
    e.itemData = e.fromData[e.fromIndex];
  }

  onTaskDrop(e: any) {
    e.itemData = e.fromData.tareas[e.fromIndex];
    e.itemData.id_estado = e.toData.estado.id;
    e.itemData.orden = e.toIndex;
    //llamo a la api
    incidenciaModule
      .modificarincidencia(new incidencia(e.itemData))
      .then(() => this.GetIncidencias());
  }

  get_style() {
    let alto = this.$vuetify.breakpoint.height;
    return "max-height:" + (alto - 218) + "px";
  }

  getPriorityStyle(task: incidenciaAllDto) {
    return ` background:${task.prioridad.color}`;
  }

  onListReorder(e: any) {
    const list = this.lists.splice(e.fromIndex, 1)[0];
    this.lists.splice(e.toIndex, 0, list);

    const status = this.estados.splice(e.fromIndex, 1)[0];
    this.estados.splice(e.toIndex, 0, status);
  }

  editar(obj: incidenciaAllDto) {
    this.id_inicencia = obj.id;
    this.show_dialog_incident = true;
  }

  nueva_incidencia() {
    this.$router.push({ name: RouterNames.proyectoskanban });
  }
}
